*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
}

.navbar-li{
font-weight: 600;
line-height: 24px;
}

.zoom-container {
    overflow: hidden;
  }
  
  .zoom-container img {
    transition: transform 0.4s ease;
  }
  
  .zoom-container:hover img {
    transform: scale(1.1);
  }

  .move-up-container {
    /* overflow: hidden; */
  }
  
  .move-up-container img {
    transition: transform 0.3s ease;
  }
  
  .move-up-container:hover img {
    transform: translateY(-7%);
  }

  .zoom2{
    transition: 500ms;
  }
  .zoom2:hover{
    transform: scale(1.08);
  }

  .zoom3{
    transition: 500ms;
  }
  .zoom3:hover{
    transform: scale(1.03);
  }

/* Media query for larger screens */
@media screen and (max-width: 767px) {
    .home-btn{
        width: 50%;
    }
    .home-head{
        font-size: 35px;
        line-height: 50px;
    }
    .home-txt{
        font-size: 16px;
    }
    .about-us-head{
        font-size: 36px;
        font-weight: 100;
        line-height: 55px;
    }
    .our-service-div{
        padding-top: 600px;
    }
    .our-service-div2{
        padding-top: 610px;
    }
    .gal-img2{
        width: 50%;
    }
    .gal-img1{
        width: 50%;
    }
    .gal-img3{
        width: 75%;
    }
    .gal-img4{
        width: 25%;
    }
    #home-btn{
        font-size: 17px;
        line-height: 34px;
        font-weight: 500;
    }
    .our-services-sub-div{
        height: 346px;
    }
    .about-us-image{
        height: 800px;
    }

    .user-img{
        /* object-fit: cover; */
        display: block;
        margin: auto;
        width: 40%;
        height: 100%;
        /* object-position: center; */
    }
    .about-sub-div{
        /* height: 336px; */
        width: 100%;
    }
}

@media screen and (min-width: 768px) and (max-width:991px){
    .home-btn{
        width: 30%;
    }

    .user-img{
        object-fit: cover;
        width: 50%;
        height: 100%;
        object-position: center;
        position: absolute;
        right: -2px;
    }
    .about-sub-div{
        height: 336px;
        width: 75%;
    }
}

@media screen and (min-width: 992px) {
    .blog-image{
        height: 478px;
    }

    .about-us-image{
        height: 336px;
    }
    .about-sub-div{
        height: 336px;
        width: 75%;
    }

.about-us-head{
    font-size: 46px;
    font-weight: 100;
    line-height: 65px;
}
.gal-img3{
    width: 25%;
}
.gal-img4{
    width: 75%;
}
.home-txt{
    font-size: 22px;
}
.home-btn{
    width: 25%;
}

.our-service-div{
    padding-top: 80px;
}

.home-head{
    font-size: 62px;
    line-height: 82px;
}
.gal-img2{
    width: 83%;
}
.gal-img1{
    width: 17%;
}
#home-btn{
    font-size: 20px;
    line-height: 36px;
    font-weight: 500;
}
.our-services-sub-div{
    height: 346px;
}
.user-img{
    object-fit: cover;
    width: 50%;
    height: 100%;
    object-position: center;
    position: absolute;
    right: -2px;
}
}

@media screen and (min-width: 1400px){
    .our-services-sub-div{
        height: 406px;
    }
}

.input::placeholder{
font-size: 14px;
font-weight: 400;
line-height: 16px;
color: #142E3880;
}
.input{
    background-color: #F1F9EB;
}

.main-container{
padding-top: 69px;
}

.images{
    object-fit: contain;
    object-position: center;
}
.about-us-image{
    background-image: url('../src//images/K444.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.about-sub-div{
    background-image: url('../src//images/K222.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.footer-li{
    color: #0A5E1B;
    cursor: pointer;
}
/* About us page */
.font1{
    font-family: "Cormorant Garamond", serif;
    font-weight: 300;
  font-style: normal;
}
.font2{
    font-family: "Poppins", sans-serif;
}
.font3{
    font-family: "Outfit", sans-serif;
}
.font4{
    font-family: "Asap", sans-serif;
}
.font5{
    font-family: "Mulish", sans-serif;
}
.font6{
    font-family: "Alegreya", serif;
}
.font7{
    font-family: "Roboto", sans-serif;
}

.back-to-top-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    background-color: #51AF2D;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .back-to-top-btn.show {
    opacity: 1;
  }
  
  .back-to-top-btn.hide {
    opacity: 0;
  }

  .navbar-condition{
    color: #51AF2D;
    border-bottom: 2px solid #51AF2D;
  }

  .home-div{
    /* background-image: url('../src/images/111.png'); */
    background-image: url('../src/images/Contact\ Us.png');
    height: 100%;
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
  }

  .home-text{
    font-size: 19px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 1%;
    color: #75C634;
  }
  .home-text2{
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 2%;
    color: #717171;
  }

  .navbaroffcanvas{
    transition: 0.6s ease-in-out;
  }